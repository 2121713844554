import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>CRA : Coming Soon!</h1>
    </div>
  );
}

export default App;
